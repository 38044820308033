*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Ano;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.top-1\/3 {
  top: 33.3333%;
}

.right-0 {
  right: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.order-last {
  order: 9999;
}

.order-first {
  order: -9999;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-72 {
  margin-top: 18rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.mt-48 {
  margin-top: 12rem;
}

.-ml-24 {
  margin-left: -6rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-96 {
  margin-top: -24rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-4 {
  margin-right: 1rem;
}

.-mt-36 {
  margin-top: -9rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-max {
  height: max-content;
}

.h-full {
  height: 100%;
}

.h-16 {
  height: 4rem;
}

.h-6 {
  height: 1.5rem;
}

.h-0 {
  height: 0;
}

.h-auto {
  height: auto;
}

.h-8 {
  height: 2rem;
}

.h-12 {
  height: 3rem;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.w-full {
  width: 100%;
}

.w-3\/4 {
  width: 75%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-9\/12 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-4\/6 {
  width: 66.6667%;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-initial {
  flex: 0 auto;
}

.basis-10\/12 {
  flex-basis: 83.3333%;
}

.basis-4\/12 {
  flex-basis: 33.3333%;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-end {
  place-content: end;
}

.place-items-end {
  place-items: end;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.bg-beige {
  --tw-bg-opacity: 1;
  background-color: rgb(220 203 155 / var(--tw-bg-opacity));
}

.bg-dunkelviolett {
  --tw-bg-opacity: 1;
  background-color: rgb(92 79 103 / var(--tw-bg-opacity));
}

.bg-dunkelblau {
  --tw-bg-opacity: 1;
  background-color: rgb(0 21 64 / var(--tw-bg-opacity));
}

.fill-dunkelblau {
  fill: #001540;
}

.fill-beige {
  fill: #dccb9b;
}

.stroke-dunkelblau {
  stroke: #001540;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-4 {
  padding: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.uppercase {
  text-transform: uppercase;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.leading-relaxed {
  line-height: 1.625;
}

.text-dunkelblau {
  --tw-text-opacity: 1;
  color: rgb(0 21 64 / var(--tw-text-opacity));
}

.text-weiss {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-beige {
  --tw-text-opacity: 1;
  color: rgb(220 203 155 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@font-face {
  font-family: Ano;
  src: local(Ano), url("ano-regular.62858cdc.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Ano;
  src: local(Ano), url("ano-regular-italic.40d5146c.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Ano;
  src: local(Ano), url("ano-bold.b72ee41f.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Ano;
  src: local(Ano), url("ano-bold-italic.072d7511.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

video[poster] {
  object-fit: fill;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

@media (min-width: 640px) {
  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pt-32 {
    padding-top: 8rem;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:visible {
    visibility: visible;
  }

  .md\:invisible {
    visibility: hidden;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:order-first {
    order: -9999;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:mb-32 {
    margin-bottom: 8rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:-mt-72 {
    margin-top: -18rem;
  }

  .md\:-mt-48 {
    margin-top: -12rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:-mt-12 {
    margin-top: -3rem;
  }

  .md\:-mt-16 {
    margin-top: -4rem;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-0 {
    height: 0;
  }

  .md\:h-24 {
    height: 6rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-16 {
    height: 4rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:place-content-start {
    place-content: start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:gap-x-24 {
    column-gap: 6rem;
  }

  .md\:bg-weiss {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .md\:fill-weiss {
    fill: #fff;
  }

  .md\:pt-6 {
    padding-top: 1.5rem;
  }

  .md\:pb-24 {
    padding-bottom: 6rem;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pl-48 {
    padding-left: 12rem;
  }

  .md\:pt-24 {
    padding-top: 6rem;
  }

  .md\:pl-12 {
    padding-left: 3rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-weiss {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .md\:opacity-0 {
    opacity: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:-mt-32 {
    margin-top: -8rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }
}

@media (min-width: 1280px) {
  .xl\:-mt-48 {
    margin-top: -12rem;
  }

  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:w-8\/12 {
    width: 66.6667%;
  }
}

/*# sourceMappingURL=index.50f5416e.css.map */
