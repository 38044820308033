@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Ano';
    src: local('Ano'), url(assets/ano-regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ano';
    src: local('Ano'), url(assets/ano-regular-italic.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ano';
    src: local('Ano'), url(assets/ano-bold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Ano';
    src: local('Ano'), url(assets/ano-bold-italic.woff) format('woff');
    font-weight: bold;
    font-style: italic;
}

video[poster] {
    object-fit: fill
}
